import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/marlene-burnham.jpeg";
  const fullName = `Marlene Frances (Leonard) Miller Burnham`;
  const zoomUrl =
    "https://us02web.zoom.us/j/84167927199?pwd=WExHbTdnRndhcGdPTmU0MkRoNlY1dz09";
  const zoomPhoneNumber = "(646) 558-8656";
  const zoomMeetingId = "841 6792 7199";
  const zoomMeetingPasscode = "147118";
  const birthDate = new Date(1944, 6 - 1, 28);
  const deathDate = new Date(2021, 1 - 1, 7);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 1 - 1, 31);
  const memorialTime = "1pm EST";
  const inviteText = `Due to the current restrictions we all face, in lieu of a physical ceremony, the family and friend’s of Marlene Frances (Leonard) Miller Burnham invite you to a virtual service honoring her life.`;
  const inviteChild = <></>;
  const obituary = `Marlene "Mar" Frances (Leonard) Miller Burnham, 76, passed away on Thursday, January 7, 2021, after complications from COVID-19.
  \nMarlene was born in Pittsburgh, PA, on June 28, 1944, to Bernard and Nellie (Lawson) Leonard. She was a graduate of North Hills High School in Pittsburgh and worked for Blue Cross in PA. On a blind date, through extended family, in Muskegon, Michigan, she met Melvin Miller. They married and she moved to Michigan not long after. Marlene always called Pittsburgh "home", her next love was Irons, MI where her house overlooked a beautiful creek in the woods. She also lived in Ludington, and for the last eight years, close to her daughter in Kalamazoo at Friendship Village. She worked for Consumers Power Company, both in Muskegon and at the Ludington Pumped Storage Plant, for 30 years. Her office looked out over Lake Michigan, not a bad view to have every day.
  \nMarlene was an avid and skilled quilter, gardener, antique collector, animal lover, and shopper. Seriously, she could shop for hours. Her smile and laughter were contagious and her positivity admirable. She was happy, fun, and fantastically feisty. There was not a moment that she wasn't busy doing something. She was a supportive, loving, and wonderful mom and loved her family.
  \nMarlene was preceded in death by her second husband, Richard "Dick" Burnham.
  \nSurviving is her daughter, Michelle Miller and husband Kent Elliott, grandchildren; Ry and Ivy Elliott of Richland; stepdaughter, Jeanine (Burnham) Ream of Oscoda; step-grandchildren, Noah and Jovian Ream; her brother, David Leonard; sister, Ruth Leonard and partner Chris Drgon; niece, Kriss Gielas, husband Joe Gielas, and her family all of Pittsburgh, PA.
  \nIn lieu of flowers, the family asks you to go outside, get your fingers dirty, and plant something in remembrance; a tree, bush, or flowers. Also, purchase (and wear) a mask for yourself and your family - that's what Marlene would want.`;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedDirectUrl = [
    {
      url: "https://storage.googleapis.com/afterword-static/recordings/Marlene%20Frances%20(Leonard)%20Miller%20Burnham.mp4",
      poster: "/images/people/marlene-intro.png",
      title: "Memorial Recording",
    },
  ];
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild={inviteChild}
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
      dropboxUrl={dropboxUrl}
    >
      <p>
        Personal memories, messages, and photos are encouraged and can be posted
        here at{" "}
        <a href="//www.farleyestesdowdle.com" target="_blank" rel="noreferrer">
          www.farleyestesdowdle.com
        </a>
        . Farley Estes Dowdle Funeral Home and Cremation Care is assisting
        Marlene's family.
      </p>
    </StoryPage>
  );
};

export default ThisPage;
